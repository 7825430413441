import React, { useEffect, useState, useRef } from "react";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import styled from "styled-components";
import "../globalStyles/normalize.css";
import "../globalStyles/styles.css";
import Marquee from "react-fast-marquee";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { animated } from "react-spring";
import { Parallax, ParallaxProvider } from 'react-scroll-parallax';
import Row from "../components/about-page-components/LayoutRow";
import Column from "../components/about-page-components/LayoutColumn";
import Spacer from "../components/about-page-components/LayoutSpacer";
import FooterCredits from '../components/FooterCredits'
import Close from "../components/Close";
import FsLightbox from 'fslightbox-react';
import './lightbox-custom-style.css'
import TloLogo from "../components/TloLogo";

const Button = styled.button`
  z-index: 9998;
  position: fixed;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: white; 
  background: none;
  border: none;
  padding: 0;
  font: inherit;
  outline: inherit;
  
  margin-top: 30px;
  cursor: pointer;
  right: 42px;

  h3 {
    margin: 2px;
  }

  @media (max-width: 1200px) {
    width: 48px;
    margin-left: -24px; 
  }
  
`;


const StyledMarquee = styled(Marquee)`
  overflow: hidden;
  * p {
    margin: 0;
  }
  div.marquee {
    margin-right: 50px;
  }
`;

const ParallaxWrapper = styled.div`
  height: 100%;
  position: relative;
  z-index: 99;
`

const Section = styled.div`
  background: #72FF00;
  padding: 30px 0;
  position: relative;
`

const AboutPageWrapper = styled(animated.div)`
  background-color: white;
  position: relative;
  height: 100%;
  position: relative;
  margin-top: 59px;
  padding-top: 8px;
  overflow-x: hidden;

  .instagramLinkDesktop {
      transform: rotate(90deg) translate(-50%, -50%);
      font-size: 25px;
      position: fixed;
      top: calc(50% + 56px);
      right: 5%;
      width: auto;
      right: 4px;
      z-index: 9998;
   }

   .instagramLinkMobile {
     display: none
   }

  .portraitCredits {
      transform: rotate(270deg);
      width: 150px;
      position: absolute;
      font-size: 10px;
      left: -90px;
      top: 230px;
   }

  .about_mobile {
      display: none;
  }
  .about_desktop {
      display: initial;
      z-index: 99999;

    .firstPortrait {
        margin-top: 54px;
    }
    .secondPortrait {
        margin-top: 48px;
        position: absolute !important;
        right: 0
    }
    .secondPortraitWrapper {
      position: relative;
    }

  }

  @media (max-width: 1200px) {
    .instagramLinkDesktop {
      display: none;
    }

    .instagramLinkMobile {
      margin-top: 32px;
      display: initial;
    }

    .about_mobile {
      display: initial;
      z-index: 99999;

      .spacer {
        margin-bottom: 80px;
      }

      .address {
        margin-top: 32px;
      }

      .green {
        color: #72FF00;
      }

      .firstPortrait {
        margin-top: 10px;
        margin-left: 20px;
        margin-bottom: 20px;
      }
      .secondPortrait {
          margin-top: 10px;
          margin-left: 20px;
          margin-bottom: 20px;
      }
      .portraitCredits {
        left: -65px;
        top: 180px;
      }
    }
    .about_desktop {
      display: none;
    }
  }
`;

const GatsbyImageWrapper = styled.button`
  margin-bottom: 32px;
  margin-right: 32px;
  width: 96px;
  height: 96px;
  display: inline-block;
  background: none;
  border: none;
  padding: 0;
  outline: none;
`;

const StyledGatsbyImage = styled(GatsbyImage)`
  width: 96px;
  height: 96px;
  filter: grayscale(100%);
  transition: opacity 0.2s ease-in-out;

  :hover {
    opacity: 0.7;
  }
`;


const AboutComponent = ({ innerRef, }) => {
  const [islightboxOpen, setlightboxOpen] = useState(false)
  const [images, setImages] = useState(null)
  const [photoIndex, setPhotoIndex] = useState(0)
  const [toggler, setToggler] = useState(false)

  const data = useStaticQuery(graphql`
    query AboutPageQuery {
      contentfulTloAboutPage {
        michalNamePl
        michalNameEn
        licenseNumber
        michalPicture1Author
        michalPicture2Author
        main {
          raw
        }
        address {
          raw
        }
        officePl {
          raw
        }
        officeEn {
          raw
        }
        team {
          raw
        }
        teamPastMembers {
          raw
        }
        jobsPl {
          raw
        }
        jobsEn {
          raw
        }
        michalPl {
          raw
        }
        michalEn {
          raw
        }
        publications {
          raw
        }
        officePicture {
          gatsbyImageData(width: 1200, height: 900)
        }
        michalPicture1 {
          gatsbyImageData
        }
        michalPicture2 {
          gatsbyImageData
        }
        footerCreditsPhotos {
          raw
        }
        footerCreditsDesign {
          raw
        }
        footerCreditsCode {
          raw
        }
        footerCreditsTypeface {
          raw 
        }
      }
      allContentfulTloTeamMembers(sort: { id: DESC }) {
        edges {
          node {
            id
            name
            description {
              raw
            }
            image {
              gatsbyImageData
            }
          }
        }
      }
      allContentfulTloProject(sort: { fields: orderNumber, order: DESC }) {
        edges {
          node {
            title
            description {
              raw
            }
            location
            orderNumber
            desktopImagesContainer {
              image {
                gatsbyImageData(
                  width: 2100,
                  height: 1200
                )
                file {
                  url
                  fileName
                }
              }
            }
          }
        }
      }
    }
  `);

  const projects = data.allContentfulTloProject.edges;
  const teamMembers = data.allContentfulTloTeamMembers.edges;
  const about = data.contentfulTloAboutPage;

  const handleLightboxDisplay = ({ current, projectImages }) => {
    setImages(projectImages.map(img => img.image.file.url))
    setPhotoIndex(current)
  }

  useEffect(() => {
    images && setToggler(true)
  }, [images])


  const aboutSectionElements = [
    <>
      <h3
        style={{
          textTransform: "uppercase",
          letterSpacing: "1px",
          textAlign: "center",
          position: "relative",
        }}
      >
        About
      </h3>
    </>,
    <>
      <Spacer val={112} />
      <Row cols="800px auto" gutter="96px">
        <Column>
          <h2>{documentToReactComponents(JSON.parse(about.main.raw))}</h2>
        </Column>
        <Column >
          <h3 className='address' >{documentToReactComponents(JSON.parse(about.address.raw))}</h3>
          <h3>
            <a className='instagramLinkMobile' href="https://www.instagram.com/tlo_archi" title="Odwiedź moje konto na instagramie" rel="noopener noreferrer" target="_blank">Instagram</a>
          </h3>
        </Column>
      </Row>
    </>,
    <>
      <Spacer val={123} />
      <Row cols="416px 704px" gutter="32px">
        <Column>
          <h4>TŁO</h4>
        </Column>
        <Column>
          <h4>{documentToReactComponents(JSON.parse(about.officePl.raw))}</h4>
        </Column>
      </Row>
    </>,
    <>
      <Spacer val={152} />
      <Row cols="224px 704px" gutter="32px">
        <Column>
          <h4 className='green'>TŁO</h4>
        </Column>
        <Column>
          <h4 className='green'>{documentToReactComponents(JSON.parse(about.officeEn.raw))}</h4>
        </Column>
      </Row>
    </>,
    <>
      <Spacer val={128} />
      <Row cols="224px 704px" gutter="32px">
        <Column></Column>
        <Column>
          <GatsbyImage image={getImage(about.officePicture)} alt="" />
        </Column>
      </Row>
    </>,
    <>
      <Spacer val={96} />
      <Row cols="224px 704px" gutter="32px">
        <Column>
          <h4>TŁO TEAM PRESENT</h4>
          <h4>AND PAST</h4>
        </Column>
        <Column>
          <h4 style={{ height: "23.37px" }}>
            <StyledMarquee
              style={{ position: "absolute" }}
              gradient={false}
              speed={10}
              direction='right'
            >
              {documentToReactComponents(JSON.parse(about.team.raw))}
            </StyledMarquee>
          </h4>
          <h4 style={{ height: "23.37px" }}>
            <StyledMarquee
              style={{ position: "absolute" }}
              gradient={false}
              speed={25}
            >
              {documentToReactComponents(JSON.parse(about.teamPastMembers.raw))}
            </StyledMarquee>
          </h4>
        </Column>
      </Row>
    </>,
    <>
      <Spacer val={96} />
      <Row cols="36px 160px 704px" gutter="32px">
        <Column>
          <h4 style={{ whiteSpace: 'nowrap', textTransform: 'uppercase' }}> {about.michalNameEn} </h4>
        </Column>
        <Column className='firstPortraitWrapper' >
          <GatsbyImage
            className='firstPortrait'
            image={getImage(about.michalPicture1)}
            alt={about.michalNameEn}
          />
        </Column>
        <Column>
          <h4>{documentToReactComponents(JSON.parse(about.michalEn.raw))}</h4>
        </Column>
      </Row>
    </>,
    teamMembers
      .sort((a, b) => {
        const getSurname = (name) => name.split(' ').pop().toUpperCase();
        return getSurname(a.node.name).localeCompare(getSurname(b.node.name));
      })
      .map((member, index) => <React.Fragment key={member?.node?.id}>
        <Spacer val={96} />
        <Row cols={index % 2 === 0 ? "224px 160px 704px" : "36px 160px 704px"} gutter="32px">
          <Column>
            <h4 style={{ whiteSpace: 'nowrap', textTransform: 'uppercase' }}> {member?.node?.name} </h4>
          </Column>
          <Column className='firstPortraitWrapper' >
            <GatsbyImage
              className='firstPortrait'
              image={getImage(member?.node?.image?.gatsbyImageData)}
              alt={member?.node?.name}
            />
          </Column>
          <Column>
            <h4>{documentToReactComponents(JSON.parse(member?.node?.description?.raw))}</h4>
          </Column>
        </Row>
      </React.Fragment>),

    <>
      <Spacer val={152} />
      <Row cols="416px 704px" gutter="32px">
        <Column>
          <h4>PRACA</h4>
        </Column>
        <Column>
          <h4>{documentToReactComponents(JSON.parse(about.jobsPl.raw))}</h4>
        </Column>
      </Row>
    </>,
    <>
      <Spacer val={64} />
      <Row cols="224px 704px" gutter="32px">
        <Column>
          <h4 className='green'>JOBS & INTERNSHIPS</h4>
        </Column>
        <Column>
          <h4 className='green'>{documentToReactComponents(JSON.parse(about.jobsEn.raw))}</h4>
        </Column>
      </Row>
    </>,
    <>
      <Spacer val={192} />
      <Row cols="416px 704px" gutter="32px">
        <Column>
          <h4>
            BADANIA I PUBLIKACJE / <br /> RESEARCH AND PUBLICATIONS
          </h4>
        </Column>
        <Column>
          <h4>
            {documentToReactComponents(JSON.parse(about.publications.raw))}
          </h4>
        </Column>
      </Row>
    </>,
    <>
      <Spacer val={312} />
      <Section >

        {projects.map((project, index) => {
          return (
            <Row
              key={index}
              cols="416px 704px"
              gutter="32px"
              withLine={true}
              lastOne={projects.length === index + 1}
            >
              <Column className="column">

                {project.node.desktopImagesContainer.map((img, index) => {
                  return (
                    <GatsbyImageWrapper
                      key={index}
                      onClick={() => handleLightboxDisplay({ current: index, projectImages: project.node.desktopImagesContainer })}
                    >

                      <StyledGatsbyImage
                        image={getImage(img.image)}
                        alt={img.image.file.fileName}
                      />
                    </GatsbyImageWrapper>
                  );
                })}

              </Column>
              <Column>
                <h4>
                  {project.node.title} <br />
                  <span style={{ whiteSpace: "pre-line" }}>
                    {project.node.description &&
                      documentToReactComponents(
                        JSON.parse(project.node.description?.raw)
                      )}
                  </span>
                  {project.node.location} <br />
                  <br />
                </h4>
              </Column>
            </Row>
          );
        })}
      </Section>
      <footer
        style={{ background: "#000", padding: "40px 0 22px", color: "#fff" }}
      >
        <FooterCredits
          footerCreditsPhotos={about.footerCreditsPhotos}
          footerCreditsDesign={about.footerCreditsDesign}
          footerCreditsCode={about.footerCreditsCode}
          footerCreditsTypeface={about.footerCreditsTypeface}
        />

        <h3 style={{ textAlign: "center", margin: '40px 0 20px' }}>
          {`©${new Date().getFullYear()}—${about.licenseNumber}`}
        </h3>
      </footer>
    </>
  ]

  const containerRef = useRef();
  const [contRef, setContRef] = useState("");

  useEffect(() => {
    setContRef(containerRef.current);
  }, [containerRef]);

  const overwriteStyles = () => {
    const green = 'rgb(114,255,0)'
    const $fslightboxPrev = document.querySelector(".fslightbox-slide-btn-previous-container > div > svg > path");
    const $fslightboxNext = document.querySelector(".fslightbox-slide-btn-next-container > div > svg > path");
    const $fslightboxNumberContainer = document.querySelector(".fslightbox-slide-number-container");
    const $fslightboxNumberSlash = document.querySelector(".fslightbox-slide-number-container .fslightbox-slash");

    $fslightboxNumberContainer.style.color = green;
    $fslightboxNumberSlash.style.background = green;
    [$fslightboxPrev, $fslightboxNext].forEach(item => {
      if (item) {
        item.style.fill = green;
      }
    })
  }

  return (
    // <ParallaxProvider scrollContainer={contRef}>
    <>
      {/* {!islightboxOpen && aboutOpen && <Button onClick={handleAboutOpen}>
        <Close />
      </Button>} */}

      {images && <FsLightbox
        onClose={() => {
          setImages(null)
          setToggler(false)
        }}
        onOpen={
          overwriteStyles
        }
        toggler={toggler}
        sources={images}
        sourceIndex={photoIndex}
      />}

      <AboutPageWrapper
        // style={style}
        // invisible={invisible}
        ref={innerRef}
      >
        {/* <ParallaxWrapper ref={containerRef}  > */}

        <Spacer val={20} />
        <div className='about_desktop'>
          <TloLogo fixed />

          {aboutSectionElements.map((elem, index) => {
            const factor = (index % 2 === 0) ? 22 : 5
            return <div key={index} > {elem} </div>
            // return (
            //   <Parallax key={index} y={[-factor, factor]} style={{ marginTop: factor, marginBottom: factor }} >
            //     <div > {elem} </div>
            //   </Parallax>
            // )
          })}
        </div>
        <div className='about_mobile'>
          <TloLogo fixed />

          {aboutSectionElements.map((elem, index) => {
            return (
              <div key={index}> {elem} </div>
            )
          })}
        </div>

        {/* </ParallaxWrapper> */}

        {/* {!islightboxOpen && <a className='instagramLinkDesktop' href="https://www.instagram.com/tlo_archi" title="Odwiedź moje konto na instagramie" rel="noopener noreferrer" target="_blank">Instagram</a>} */}
      </AboutPageWrapper >
      {/* // </ParallaxProvider> */}
    </>
  );
};

export default AboutComponent;
